import { useEffect, useState } from 'react';
import { useRouter } from "next/router";
import {
    getDataImageFromRelated,
    getDataImageSelectedFromRelated,
    getDataVideoFromRelated,
    getImageByClippings,
    getStringWithoutHtml,
    getStringWithoutAcute,
    nsSite,
    compareDateIsOlder,
    normalizeString,
    isValidDate,
    getDateFormatZulu,
    getMostRecentDate,
    getDateForLiveblog,
} from "helpers";
import {
    getTypeNews,
    getClippingsByTypeNews,
    getTypeNewsForAnalitics,
    threads,
} from "../helpers";
import { UseSupportedBrowser } from "@/hooks/index";
import { sizeBanner } from "@/shared/DFP/News/mapping";
import { BannerCajaGris, BannerCollapse } from "@/shared/ContentBanner";

export const useNews = (props) => {

    const { content, ...rest } = props;

    const router = useRouter();
    const [currentImageSlide, setCurrentImageSlide] = useState(null);
    const [latestVideoRigthColumn, setLatestVideoRigthColumn] = useState([]);
    const [latestRigthColumn, setLatestRigthColumn] = useState([]);
    const [mostSeenSuscription, setMostSeenSuscription] = useState([]);
    const [innerWidth, setInnerWidth] = useState(0);
    const [showRewarded, setShowRewarded] = useState(false);
    const [bucketsHeader, setBucketsHeader] = useState([]);
    const [newsToday, setNewsToday] = useState([]);
    const [countComments, setCountComments] = useState(0);
    const [ualterEnable, setUalterEnable] = useState(false);

    //
    const loadRigthColumn = async () => {
        const fetchLatestVideo = await fetch("/api/latest/video/10");
        const dataLatestVideo = await fetchLatestVideo.json();
        setLatestVideoRigthColumn({
            name: "LatestVideoRigthColumn",
            content: dataLatestVideo?.data ?? [],
        });
        const fetchMostSeenSuscription = await fetch("/api/viewed/5/bi" + `${mostSeenSuscriptionSectionID !== false ? "?section=" + mostSeenSuscriptionSectionID : ""}`);
        const dataMostSeenSuscription = await fetchMostSeenSuscription.json();
        setMostSeenSuscription({
            name: "MostSeenSuscription",
            content: dataMostSeenSuscription?.data ?? [],
        });
        const fetchLatestRigthColumn = await fetch("/api/latest/lilanews/5" + `${latestRigthColumnSectionID !== false ? "?section=" + latestRigthColumnSectionID : ""}`);
        const dataLatestRigthColumn = await fetchLatestRigthColumn.json();
        setLatestRigthColumn({
            name: "LatestRigthColumn",
            content: dataLatestRigthColumn?.data ?? [],
        });
    };

    const loadLiveblog = async () => {

        const fetchLiveblogs = await fetch("/api/menus/liveblogs");
        const dataLiveblogs = await fetchLiveblogs.json();
        const data = dataLiveblogs?.data?.items;

        let urgent = [];
        try {
            if (typeWeb === "normal" || typeWeb === "small") {
                const responseUrgent = await fetch("/api/menus/urgente");
                const responseData = await responseUrgent.json();
                urgent = responseData?.data?.items ?? [];
            }
        } catch (error) {
            urgent = [];
        }

        setBucketsHeader({ liveblogs: data ?? [], urgent });
    };

    const loadNewsToday = async () => {
        const fetchNewsToday = await fetch("/api/menus/noticiashoy");
        const dataNewsToday = await fetchNewsToday.json();
        const data = dataNewsToday?.data?.items?.length > 0
            ? dataNewsToday.data?.items
            : [];
        setNewsToday(data);
    };

    const loadCountComments = async () => {
        const fetchCountComments = await fetch("/api/viafoura/comments/" + content?._id);
        const dataCountComments = await fetchCountComments.json();
        setCountComments(dataCountComments?.data?.total_visible_content ?? 0);
    };

    const saveNewsToLocalStorage = () => {
      const setLocalStorage = (news, id, readDate, modifiedDate) => {
        news.push({ id, readDate, modifiedDate });
        localStorage.setItem("news_reads", JSON.stringify(news));
      };

      let news = JSON.parse(localStorage.getItem("news_reads")) || [];
      const existingNoteIndex = news.findIndex(
        (item) => item.id && item.id === content?._id
      );
      if (existingNoteIndex === -1) {
        setLocalStorage(
          news,
          content?._id,
          new Date().toISOString(),
          new Date(content.modificatedAt).toISOString()
        );
      } else {
        const modifiedDateSave = new Date(news[existingNoteIndex].modifiedDate);
        const modifiedDateCurrent = new Date(content.modificatedAt);
        if (modifiedDateCurrent > modifiedDateSave) {
          setLocalStorage(
            news,
            content?._id,
            new Date().toISOString(),
            new Date(content.modificatedAt).toISOString()
          );
        }
      }
    };
    let urlCanonical = content.metadata?.urlCanonical ? content.metadata.urlCanonical : "";
    
        useEffect(() => {
        if (window?.document) {
            setInnerWidth(document.body.clientWidth);
        }
        if (typeof window.getRewarded == "undefined") {
            window.getRewarded = () => {
                setShowRewarded(true);
            };
        }
        loadRigthColumn();
        if (!typeWithoutBucketsHeader.includes(typeWeb)) {
            loadLiveblog();
        }
        loadNewsToday();
        if (content?.metadata?.payWallAccessControl == "freemium") {
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:187125,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            
            window.marfeel.cmd.push([
                "compass",
                function (compass) {
                    compass.setPageVar("closed", "dynamic-paywall");
                },
            ]);
        }
        if (content?.metadata?.payWallAccessControl == "suscribers-only") {
            window.marfeel.cmd.push([
                "compass",
                function (compass) {
                    compass.setPageVar("closed", "hard-paywall");
                },
            ]);
        }
        setTimeout(() => {
            saveNewsToLocalStorage();
        }, 20000); // 30 segundos
        loadCountComments();
        setUalterEnable(ualterStatus);
    }, []);

    //

    let querySectionId, keySection;
    querySectionId = content?.sections?.[0]?._id;
    keySection = "sections";
    if (!!content?.mainSectionTree) {
        querySectionId = content.mainSectionTree?.[0]?._id;
        keySection = "mainSectionTree";
    }
    const latestRigthColumnSectionID = (!!content?.[keySection]?.[0]?.metadata?.latestRigthColumn) ? querySectionId : false;
    const mostSeenSuscriptionSectionID = (!!content?.[keySection]?.[0]?.metadata?.mostSeenSuscription) ? querySectionId : false;

    const idBucketDiscoverNews = (!!content?.[keySection]?.[0]?.metadata?.idBucketDiscoverNews) ? content[keySection][0].metadata.idBucketDiscoverNews : "discover-lx";
    const idBucketRecommender = (!!content?.[keySection]?.[0]?.metadata?.idBucketRecommender) ? content[keySection][0].metadata.idBucketRecommender : false;

    const ualterStatus =
        (content?.metadata?.ualterDisabled === undefined || content?.metadata?.ualterDisabled === false)
            ? true
            : false;

    const modificatedAtIsOlder = compareDateIsOlder(content?.modificatedAt, content?.publishedDate);

    const liveBlogItems = content?.body?.filter((item) => item.type === "liveblog");
    const liveBlogBodies = liveBlogItems?.map((liveBlogItem) =>
        liveBlogItem?.value?.body?.filter((item) => item.type === "text")
    );
    const noticias = liveBlogBodies;
    let liveblogBodyUpdate = "";

    if (liveBlogItems?.length > 0) {
        if (liveBlogItems[0]?.value?.modificatedAt) {
            liveblogBodyUpdate = getDateFormatZulu(liveBlogItems[0].value.modificatedAt);
        } else if (
            isValidDate(noticias?.[0]?.[0]?.dateParagraph)
        ) {
            liveblogBodyUpdate = getDateFormatZulu(noticias[0][0].dateParagraph);
        } else {
            liveblogBodyUpdate = getDateFormatZulu(content?.modificatedAt);
        }
    }

    const firstIndexLiveBlog = content?.body?.findIndex((item) => item.type === "liveblog");
    const highlightedParagraphsLiveblog = content?.body?.[firstIndexLiveBlog]?.value?.body
        ? threads(content.body[firstIndexLiveBlog].value.body)
            .filter((item) => item?.[0]?.rating)
            .reduce((acc, item) => {
                const foundItem = item.find(
                    (el) => el.type === "text" && el.dateParagraph
                );
                if (foundItem) {
                    acc.push(foundItem);
                }
                return acc;
            }, [])
        : [];
    highlightedParagraphsLiveblog.sort((a, b) => {
        const dateA = new Date(a.dateParagraph);
        const dateB = new Date(b.dateParagraph);
        return dateB - dateA;
    });

    const lastUpdateLiveblog = getMostRecentDate(liveblogBodyUpdate, content?.modificatedAt, rest?.isPreview);

    const accesibleForFree = content?.metadata?.payWallAccessControl
        ? content.metadata.payWallAccessControl
        : "freemiun";

    const footerVisible = !!content?.metadata?.footer_visible;
    const typeWeb = getTypeNews(content?.metadata.typeWeb, content?.specialAuthors);
    const typeWithoutBucketsHeader = ["nota_libre"];
    const typeWithoutNewsTodayHeader = ["nota_libre"];
    if (
        typeWithoutNewsTodayHeader.includes(typeWeb) &&
        rest?.menu?.noticiashoy?.item?.items
    ) {
        rest.menu.noticiashoy.item.items = [];
    }
    const dataImageRelated = getDataImageFromRelated(content?.related);
    const dataImageHeader = getImageByClippings(
        getClippingsByTypeNews(typeWeb),
        dataImageRelated.clippings
    );
    dataImageHeader.epigraphe = dataImageRelated.epigraphe;

    const dataImageManualCrop = getDataImageSelectedFromRelated(content?.related);
    if (dataImageManualCrop.image && dataImageManualCrop.image !== "#") {
        dataImageHeader.desktop = dataImageManualCrop;
        dataImageHeader.mobile = dataImageManualCrop;
    }
    const dataImageShare = getImageByClippings(
        getClippingsByTypeNews("schema"),
        dataImageRelated.clippings
    );

    const dataImagesBody = content?.body
        .filter(({ type }) => type == "image" || type == "liveblog")
        .map(({ type, value, epigraphe, selectedClipping }) => {
            if (type == "image") {
                return {
                    epigraphe: epigraphe,
                    clippings: value.clippings,
                    selectedClipping: selectedClipping,
                };
            }
            // es liveblog
            let imagesLiveBlog = value?.body
                ?.filter((element) => element.type == "image")
                .map(({ value, epigraphe }) => {
                    return {
                        epigraphe: epigraphe,
                        clippings: value.clippings,
                    };
                });
            return imagesLiveBlog;
        })
        .flat();

    const showDialogSlider = (index = 0) => setCurrentImageSlide(index);

    const parseSummary = getStringWithoutHtml(content?.summary ?? "");
    const relatedNews = content?.related?.lilanews || [];
    let relatedVideosLoop = content?.related?.videosOpenLoop?.[0]?.value ?? "";
    let relatedVideos = content?.related?.videos?.[0]?.value ?? "";
    const dataVideoHeader = getDataVideoFromRelated(content?.related);
    const mam = content?.related?.mams?.[0]?.value
        ? { enabled: true, ...content.related.mams[0].value }
        : { enabled: false };
    const sectionNameAds = content?.sections?.[0]?.name?.replace(/\s+/g, "-").toLowerCase();
    const sectionName = content?.sections?.[0]?.metadata?.section
        ? content.sections[0].metadata.section
        : content?.sections?.[0]?.name;
    const sectionUrl = content?.sections?.[0]?.url;
    const sectionId = content?.sections?.[0]?._id;
    const sectionData = {
        name: sectionName,
        url: sectionUrl,
    };

    //traemos todas las secciones a las que pertenezca la nota
    const sectionNames = content?.sections?.map((section) => {
        if (section.name) {
            return section.name;
        }
        if (section?.metadata?.section) {
            return section.metadata.section;
        }
        return "";
    });

    const idsJwPlayers = {
        main: null,
        embed: null,
        embedUpright: null,
    };
    if (content?.sections?.[0]?.metadata?.idJWPlayer) {
        idsJwPlayers.main = content?.sections[0].metadata.idJWPlayer;
    } else if (content?.mainSectionTree?.[0]?.metadata?.idJWPlayer) {
        idsJwPlayers.main = content?.mainSectionTree[0].metadata.idJWPlayer;
    } else {
        idsJwPlayers.main = process.env.NEXT_PUBLIC_JWPLAYER_ID;
    }

    if (content?.sections?.[0]?.metadata?.idJWPlayerEmbedUpright) {
        idsJwPlayers.embedUpright = content.sections[0].metadata.idJWPlayerEmbedUpright;
    } 

    if (content?.sections?.[0]?.metadata?.idJWPlayerEmbed) {
        idsJwPlayers.embed = content.sections[0].metadata.idJWPlayerEmbed;
    } 
    if (content?.mainSectionTree?.[0]?.metadata?.idJWPlayerEmbed) {
        idsJwPlayers.embed = content.mainSectionTree[0].metadata.idJWPlayerEmbed;
    } 
    if (!idsJwPlayers.embed) {
        idsJwPlayers.embed = process.env.NEXT_PUBLIC_EMBEB_JWPLAYER_ID;
    }
    
    const sectionIdJWPlayerEmbed = idsJwPlayers.embed;
    const sectionIdJWPlayerEmbedUpright = idsJwPlayers.embedUpright;
    const sectionIdJWPlayerLead = idsJwPlayers.main;
    
    const dataAnalitics = {
        asPath: router.asPath,
        name: `noticias.${content?.gtm_name}`,
        newsType: getTypeNewsForAnalitics(typeWeb),
        notaId: content?._id,
        nsSite: nsSite(content?.sections),
        pageType: "nota",
        publishedDate: content?.publishedDate ?? new Date(),
        sections: normalizeString(sectionData.name),
        section: sectionData.name,
        summary: parseSummary,
        tags: content?.tags,
        sectionNames: sectionNames,
        authors: content?.authors ? content?.authors?.[0]?.name : "Redacción Clarín",
        thumbnail: dataImageHeader.mobile.image,
    };

    const dataMeter = {
        tags: content?.tags,
        metadata: content?.metadata,
        pageType: "news",
        section: content?.sections?.[0]?.url?.replace(/\//g, ","),
        mainSectionTree: content?.mainSectionTree,
        authors: content?.authors ? content?.authors?.[0]?.slug : null,
        paywall: content?.metadata?.payWallAccessControl
            ? content?.metadata.payWallAccessControl
            : "freemiun",
        typeWeb: typeWeb,
        authorName: content?.authors ? content?.authors?.[0]?.name : "Redacción Clarín",
        sectionNames: sectionNames,
        newsId: content?._id,
    };

    const sectionAdsPath = content?.sections?.[0]?.metadata?.adsPath;

    const typeWithoutBannerHorizantalOne = ["nota_libre", "live_blog"];
    const bannerHorizantalOneEnable = !typeWithoutBannerHorizantalOne.includes(typeWeb);
    const typeWithoutSubheader = ["nota_libre"];
    const subheaderEnable = !typeWithoutSubheader.includes(typeWeb);
    const typeWithContainer100 = ["nota_libre"];
    const Container100 = typeWithContainer100.includes(typeWeb);
    const newsSeo = content?.related?.lilanewsSeo ? content?.related?.lilanewsSeo : [];
    const freeHtml = content?.related?.freehtml?.[0]
        ? content?.related.freehtml[0] : null;
    const titleEnableFreeHtml = !(content?.freeHtml && !content?.metadata.titleEnable);

    const typeWithoutBannerHorizantalThree = ["nota_libre", "live_blog"];
    const bannerHorizantalThreeEnable = !typeWithoutBannerHorizantalThree.includes(typeWeb);
    const noAccuteString = getStringWithoutAcute(parseSummary);
    const typeWithoutBanners = ["nota_libre", "live_blog"];
    const withoutBanners = !typeWithoutBanners.includes(typeWeb);

    const hasRelated =
        (freeHtml == null && dataImageHeader.desktop.image == "#" && dataVideoHeader == null)
            ? false
            : true;

    const sectionMetadataTrinity = content?.sections?.[0]?.metadata?.trinity === undefined
        ? true
        : content?.sections?.[0]?.metadata?.trinity;

    const newsMetadataTrinity =
        (content?.metadata?.trinityDisabled === undefined || content?.metadata?.trinityDisabled === false)
            ? true
            : false;
    
    const isSupportedBrowser = UseSupportedBrowser();

    const datePowerbeans = new Date('2024-10-01T00:00:00-03:00');
    const datePowerbeansOpinion = new Date('2024-10-31T00:00:00-03:00');
    const datePublished = new Date(content?.publishedDate);
    const listPowerbeansAuthors = ['ricardo-kirschbaum','ricardo-roa','santiago-fioriti','eduardo-van-der-kooy'];
    const authorNamePowerbeans = listPowerbeansAuthors.includes(content?.authors?.[0]?.slug?.trim()) ? content?.authors?.[0]?.name?.trim() : ""
    const enabledPowerbeans = newsMetadataTrinity && ((listPowerbeansAuthors.includes(content?.authors?.[0]?.slug?.trim()) && (datePublished >= datePowerbeans)) || (['opinion'].includes(router?.query?.match?.[0]) && (datePublished >= datePowerbeansOpinion)))
    const trinityStatus = (isSupportedBrowser && sectionMetadataTrinity && newsMetadataTrinity && !enabledPowerbeans)
        ? true
        : false;
    
    const imageRelatedInclude =
        (hasRelated && !freeHtml?.value?.html && !dataVideoHeader && dataImageHeader)
            ? true
            : false;
    let sectionAdsPathFull = sectionAdsPath ? sectionAdsPath + "/nota" : "seccion/nota";
    const rightColumn = [
        {   
            name: "Banner", 
            bannerId: "caja0", 
            container: BannerCajaGris 
        },
        {   
            name: "BannerLazy", 
            bannerId: "auspicio", 
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.auspicio,
            isPreview: rest?.isPreview,
        },
        latestRigthColumn ?? { name: "" },
        {   
            name: "BannerLazy", 
            bannerId: "caja1", 
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.caja1,
            isPreview: rest?.isPreview,
        },
        {   
            name: "BannerLazy", 
            bannerId: "caja2", 
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.caja2,
            isPreview: rest?.isPreview,
        },
        mostSeenSuscription ?? { name: "" },
        {
            name: "BannerLazy",
            bannerId: "caja3",
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.caja3,
            isPreview: rest?.isPreview,
        },
        {
            name: "BannerLazy",
            bannerId: "caja4",
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.caja4,
            isPreview: rest?.isPreview,
        },
        latestVideoRigthColumn ?? { name: "" },
        {
            name: "BannerLazy",
            bannerId: "caja0b",
            container: BannerCollapse,
            adsPath: sectionAdsPathFull,
            mapping: sizeBanner.caja0b,
            isPreview: rest?.isPreview,
        },
    ];

    const dynamicRelatedNews = (() => {
        const hasDynamicRelatedNews = content?.sections?.[0]?.metadata?.dynamicRelatedNews;

        if (!hasDynamicRelatedNews) {
          return false;
        }

        const createdAt = new Date(content.publishedDate);
        const now = new Date();
        const timeDiff = now - createdAt;
        const hoursDiff = timeDiff / (1000 * 60 * 60);

        const noLiveBlogs = !(liveBlogItems?.length > 0);

        return hoursDiff > 48 && noLiveBlogs; //mostrar solo si la nota tiene mas de 48hs creada
    })();

    let keywordsFormat = content.keywords;
    if (content?.source == 13) {
        keywordsFormat = keywordsFormat == "" ? "origen-ia" : keywordsFormat + ",origen-ia"; 
    }
    return {
        ...rest,
        formatContent: {
            ...content,
            authorNamePowerbeans,
            bannerHorizantalThreeEnable,
            Container100,
            currentImageSlide,
            dataImageRelated,
            dataImagesBody,
            dataAnalitics,
            dataMeter,
            enabledPowerbeans,
            footerVisible,
            featuredContent: content?.metadata?.featuredContent,
            idBucketDiscoverNews,
            idBucketRecommender,
            isSupportedBrowser,
            imageRelatedInclude,
            sectionNameAds,
            setCurrentImageSlide,
            showRewarded,
            typeWeb,
            ualterEnable,
            withoutBanners,
            dynamicRelatedNews,
            sizeBanner,
            storyContainer: {
                data: {
                    _id: content?._id,
                    authorPlace: content?.authorPlace,
                    authors: content?.authors,
                    body: content?.body,
                    onClickButtonSlider: (index) => showDialogSlider(index),
                    canHaveComments:
                        (isSupportedBrowser && content?.metadata?.canHaveComments)
                            ? content.metadata.canHaveComments
                            : false,
                    countComments: countComments,
                    commentsStatus: (typeWeb !== "live_blog") ? true : false,
                    dataImage: dataImageHeader,
                    dataVideo: dataVideoHeader,
                    freeHtml: freeHtml,
                    hasRelated: hasRelated,
                    innerWidth: innerWidth,
                    isPreview: rest?.isPreview ?? false,
                    mam: mam,
                    modificatedAt: modificatedAtIsOlder ? content?.modificatedAt : content?.publishedDate,
                    newsSeo: newsSeo,
                    publishedDate: content?.publishedDate,
                    relatedNews: relatedNews,
                    relatedVideosLoop: relatedVideosLoop,
                    sectionIdJWPlayerLead: sectionIdJWPlayerLead,
                    sectionIdJWPlayerEmbed: sectionIdJWPlayerEmbed,
                    sectionIdJWPlayerEmbedUpright: sectionIdJWPlayerEmbedUpright,
                    sectionName: sectionData.name,
                    sections: content?.sections,
                    socialNetworkingSelect: [
                        "facebook",
                        "twitter",
                        "whatsapp",
                        "share",
                    ],
                    specialAuthors: content?.specialAuthors,
                    subtitle: content?.subtitle,
                    summary: parseSummary,
                    tags: content?.tags,
                    title: content?.title,
                    titleEnable: titleEnableFreeHtml,
                    trinityStatus: trinityStatus,
                    url: content?.url,
                    volanta: content?.subtitle,
                    widgets: rightColumn,
                    payWallAccessControl:
                        (content?.metadata?.payWallAccessControl == "suscribers-only")
                            ? true
                            : false,
                    adsPath: sectionAdsPathFull,
                    highlightedParagraphsLiveblog: highlightedParagraphsLiveblog,
                    disabledTimelineLiveblog: content?.metadata?.disabledTimelineLiveblog
                        ? content.metadata.disabledTimelineLiveblog
                        : false,
                    preloadImg: !(typeWeb === "normal"),
                    isLiveblog: (typeWeb === "live_blog"),
                    withBodyBanner: !content?.metadata?.featuredContent ?? true,
                },
                type: typeWeb,
            },
            dataHead: {
                urlCanonical:urlCanonical,
                authors: content?.authors,
                dataImageShare: dataImageShare,
                keywords: keywordsFormat,
                lastModified: (lastUpdateLiveblog != "")
                    ? getDateForLiveblog(lastUpdateLiveblog, rest?.isPreview)
                    : modificatedAtIsOlder
                        ? content?.lastModified
                        : content?.publishedDate,
                mam: mam,
                menu: rest?.menu,
                publishedDate: content?.publishedDate,
                related: content?.related,
                relatedVideos: relatedVideos,
                relatedNews: relatedNews,
                sectionId: sectionId,
                sectionUrl: sectionData.url,
                sections: content?.sections,
                subtitle: content?.subtitle,
                summary: noAccuteString,
                title: content?.title,
                body: content?.body,
                url: content?.url,
                metadata: content?.metadata,
                shared: content?.shared ?? [],
                accesibleForFree: accesibleForFree,
                createdBy: content?.createdBy
            },
            dataHeader: {
                urgent: bucketsHeader.urgent,
                liveblogs: bucketsHeader.liveblogs,
                menu: rest?.menu ?? {},
                sectionData: sectionData,
                isPreview: rest?.isPreview ?? false,
                isNews: true,
                bannerHorizantalOneEnable: bannerHorizantalOneEnable,
                subheaderEnable: subheaderEnable,
                newsToday: newsToday,
                megaWithHtml:
                    (typeWeb != "mega")
                        ? false
                        : (typeWeb == "mega" && freeHtml != null)
                            ? true
                            : false,
                showGoolgeOneTap: dataMeter.paywall === "free",
            }
        },
    }
}