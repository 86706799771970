import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ContentRewarded } from "./style";
import { getExpireDate } from "helpers";
import { handleClick } from "../Analitics/GoogleAnalytics4";
import ContentIcon from "../ContentIcon";

const Rewarded = ({ adsPath = "home" }) => {
  const { loginWithRedirect } = useAuth0();
  var rewardedSlot;

  const redirectLogin = async () => {
    handleClick("ui_interaction", "rewarded", "Boton Cerrar");
    let expDate = getExpireDate(1);
    document.cookie = `auth0_redir=${
      window.location.href
    };expires=${expDate.toUTCString()};path=/`;
    loginWithRedirect();
  };

  const dismissRewardedAd = (redirect = true) => {
    document.getElementById('modal').style.display = "none";
    googletag.destroySlots([rewardedSlot]);
    redirect && redirectLogin();
  };

  const displayModal = (type, message) => {
    var modal = document.getElementById("modal");
    modal.removeAttribute("data-type");

    if (type) {
      document.getElementById("modalMessage").textContent = message;
      modal.setAttribute("data-type", type);
    }
  };

  useEffect(() => {
    googletag.cmd.push(function () {
      rewardedSlot = googletag.defineOutOfPageSlot(
        `/242767168/clarin/${adsPath}/nota/flotante8`,
        googletag.enums.OutOfPageFormat.REWARDED
      );

      googletag.pubads().addEventListener("slotRenderEnded", function (event) {
        var slot = event.slot;
        if (slot == rewardedSlot && event.isEmpty) {
          console.log(slot + " Is empty?:", event.isEmpty);
          redirectLogin();
        }
      });

      // Slot returns null if the page or device does not support rewarded ads.
      if (rewardedSlot) {
        rewardedSlot.addService(googletag.pubads());
        googletag.pubads().refresh([rewardedSlot]);
        googletag
          .pubads()
          .addEventListener("rewardedSlotReady", function (event) {
            document.getElementById("limit-play").onclick = function () {
              event.makeRewardedVisible();
              displayModal();
            };
            document.querySelector("html").style.overflow = "hidden";
            //displayModal("reward", "Ver anuncio para acceder a la nota");
            displayModal(
              "reward",
              "Para continuar, iniciá sesión o mirá el video."
            );
          });

        googletag
          .pubads()
          .addEventListener("rewardedSlotClosed", dismissRewardedAd);

        googletag
          .pubads()
          .addEventListener("rewardedSlotGranted", function (event) {
            // Automatically close the ad by destroying the slot.
            // Remove this to force the user to close the ad themselves.
            dismissRewardedAd(false);

            document.querySelector("html").style.overflow = "auto";
          });

        googletag.enableServices();
        googletag.display(rewardedSlot);
      }
    });
  }, []);

  return (
    <ContentRewarded id="modal" className="modal">
      <div className="modalDialog">
        <a className="closeButton" href="https://www.clarin.com/"></a>
        <span id="limit">
          <ContentIcon nameIcon="ico-alert-circle" />
          LÍMITE DE NOTAS ALCANZADO
        </span>
        <p id="modalMessage" data-type="rewarded">
          Seleccioná una opción para continuar leyendo esta nota.
        </p>
        <span className="rewardButtons">
          <button id="limit-play">
            <ContentIcon nameIcon="ico-play-circle"/>
            <span className="button-rewarded">Ver Video</span>
          </button>
          <button onClick={dismissRewardedAd} id="sesion">
            <ContentIcon fill="#ffffff" nameIcon="user-solid" />
            <span className="button-rewarded">Iniciar Sesion</span>
          </button>
        </span>
      </div>
    </ContentRewarded>
  );
};

export default Rewarded;
