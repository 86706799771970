import styled from "styled-components";
import { Font, breakpoints, ColorDark } from "@/shared/Constants";

export const ContentRewarded = styled.div`
  position: fixed;
  z-index: 5;
  padding-top: 300px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .modalDialog {
    margin: auto;
    padding: 50px 15px;
    background-color: white;
    border-radius: 4px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    @media (min-width: 1023px) {
      padding: 4.5rem;
      width: 800px;
    }
  }
  #modalMessage {
    ${Font.sansRegular};
    color: #000000;
    font-size: 32px;
    letter-spacing: -0.84px;
    line-height: 36px;
    padding: 30px 0 50px;
    @media (min-width: 1023px) {
      font-size: 42px;
    }
  }
  #limit {
    ${Font.sansRegular};
    color: #cc001b;
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    svg {
      max-height: 40px;
      width: 40px;
      margin-bottom: 20px;
    }
    @media (min-width: 1023px) {
      font-size: 24px;
    }
  }
  #limit::before {
    bottom: -1rem;
    content: "";
    position: absolute;
    height: 130px;
    width: 150px;
    background-size: 150px;
    z-index: 3;
  }
  @media (max-width: 1023px) {
    #limit::before {
      bottom: -1rem;
      height: 100px;
      width: 110px;
      background-size: 110px;
    }
  }

  .grantButtons, .rewardButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  .button-rewarded {
    padding: 0.5rem;
    background: none;
    border: none;
    margin: 4px;
    ${Font.sansRegular};
    text-transform: uppercase;
    font-size: 20px;
    cursor: pointer;
  }
  #sesion {
    border: none;
    background: #cc001b;
    border-radius: 5px;
    width: 260px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    color: white;
    svg {
      width: 30px;
      max-height: 30px;
    }
  }
  #close-modal {
    border: none;
    background: #ccc;
    border-radius: 5px;
    width: 151px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  .closeButton {
    position: absolute;
    right: 15px;
    top: 20px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    @media (min-width: 1023px) {
      right: 32px;
      top: 32px;
    }
  }
  .closeButton:hover {
    opacity: 1;
  }
  .closeButton:before,
  .closeButton:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 22px;
    width: 3px;
    background-color: #333;
  }
  .closeButton:before {
    transform: rotate(45deg);
  }
  .closeButton:after {
    transform: rotate(-45deg);
  }

  #limit-play {
    background: white;
    border: 1px solid #cc001b;
    border-radius: 5px;
    width: 260px;
    align-items: center;
    display: inline-flex;
    justify-content: center;

    color: #cc001b;

    svg {
      width: 30px;
      max-height: 30px;
    }
  }
  .imgResponsive {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 1023px) {
    .button-rewarded {
      font-size: 15px;
    }
    #limit-play {
      width: 177px;
    }
    #sesion {
      width: 177px;
    }
    .imgResponsive {
      width: 18px;
      height: 18px;
    }
  }
`;
