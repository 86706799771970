//const enabledTypes = ['normal', 'mega', 'special', 'small', 'live_blog', 'nota_libre']
const enabledTypes = ['normal', 'small', 'nota_libre', 'mega', 'live_blog'];

export const getTypeNews = (type, specialAuthors = false) => {    
    if(specialAuthors) return "opinion";
    if(type === "special") type = "mega";
    return enabledTypes.includes(type) ? type : 'normal' 
}

export const getTypeNewsForAnalitics = (type) => {
    const typeNews = enabledTypes.includes(type) ? type : 'default' 
    return typeNews == 'normal' ? 'default' : typeNews;
}