import { StoryMainContainer, NoscriptContainer } from "./style.js";
import { StoryContainer } from "./components";
import Header from "@/shared/Header";
import Footer from "@/shared/Footer";
import HeadNews from "@/shared/HeadNews";
import Analitics from "@/shared/Analitics";
import BannerFlotante from "@/widgets/Banner/Templates/BannerFlotante";
import Banner from "@/widgets/Banner/Templates/Banner";
import BannerLazy from "@/widgets/Banner/Templates/BannerLazy";
import { BannerFlotante2, BannerCajaGris, BannerCollapse } from "@/shared/ContentBanner";
import DfpNews from "@/shared/DFP/News";
import Meter from "@/shared/Meter/index.jsx";
import Autorefresh from "@/shared/Autorefresh/index.jsx";
import { ContainerAuto } from "@/shared/ContainerAuto";
import Script from "next/script";
import Rewarded from "@/shared/Rewarded/index.jsx";
import { ImagesSlider } from "@/shared/ImagesSlider";
import TemplateProvider from "@/context/Template/TemplateProvider.jsx";
import { useNews } from "./hooks/useNews";
import { DynamicRelatedNews } from "./components";

const Nota = (props) => {

  const { formatContent, isPreview } = useNews(props);

  const {
    metadata,
    ualterEnable,
    showRewarded,
    typeWeb,
    footerVisible,
    Container100,
    withoutBanners,
    bannerHorizantalThreeEnable,
    isSupportedBrowser,
    dynamicRelatedNews,
    storyContainer,
    sizeBanner
  } = formatContent;

  return (
    <TemplateProvider data={{ ...props, content: formatContent }} >
      <noscript dangerouslySetInnerHTML={{
        __html: `
          <style>
            html, body {
                overflow: hidden;
            }
          </style>`,
      }} />
      <noscript>
        <NoscriptContainer>
          <figure className="noscript-logo">
            <img src="https://www.clarin.com/landings/bundles/landing/img/components/header/clarin_rojo.png?65b8340" />
          </figure>
          <p className="noscript-text">
            Para disfrutar los contenidos de Clarín es necesario que actives
            JavaScript en tu navegador.
          </p>
          <a className="noscript-button" href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}?jsDisabled=true`}>
            Volver a la Home
          </a>
        </NoscriptContainer>
      </noscript>
      {!isPreview && (
        <>
          <Meter />
          <Analitics />
          <HeadNews />
        </>
      )}
      {withoutBanners && <DfpNews />}
      <Header />
      {showRewarded && <Rewarded />}
      {isSupportedBrowser && <ImagesSlider />}
      {withoutBanners && (
        <>
          <BannerFlotante isPreview={isPreview} slotId={"1"} />
          <BannerFlotante2>
            <BannerFlotante isPreview={isPreview} slotId={"2"} />
          </BannerFlotante2>
        </>
      )}
      <StoryMainContainer>
        <ContainerAuto
          className={`${Container100 && "container100"} StoryContainer`}
        >
          <container className="NewsContainer" id="NewsContainer">
            <StoryContainer />
          </container>
          {bannerHorizantalThreeEnable && (
            <BannerCollapse className="bannerCollapse">
              <BannerLazy isPreview={isPreview} slotId={"horizontal3"} adsPath={storyContainer.data.adsPath} mapping={sizeBanner.horizontal3} />
            </BannerCollapse>
          )}
        </ContainerAuto>
      </StoryMainContainer>
      {dynamicRelatedNews &&
        <DynamicRelatedNews
          limit={2}
          provider={"bi"}
          section={formatContent?.sections?.[0]?._id}
        />
      }
      {!isPreview && metadata?.autoRefresh && <Autorefresh timerRefresh={600} />}

      {footerVisible && <Footer lastCover={props?.properties?.lastCover} />}
      <div id="modalZonda" />
      {ualterEnable && typeWeb != "live_blog" && (
        <Script
          async
          id="scriptLippmannButton"
          src="https://cdn.ualter.ai/assets/ualter.js"
          onLoad={() => {
            console.log("Script ualter has loaded");
            let newDiv = document.createElement("div");
            newDiv.id = "containerUalter";
            newDiv.className = "trinity-skip-it";
            let parentDiv = document.querySelector(
              "#storyBody .StoryTextContainer"
            );
            if (parentDiv) {
              try {
                parentDiv && parentDiv.insertBefore(newDiv, parentDiv.firstChild);
                var UALTER_ENV = "clarin";
                var UALTER_CONTAINER =
                  "#storyBody .StoryTextContainer #containerUalter";
                ualterStart(UALTER_ENV, UALTER_CONTAINER);
              } catch (error) {
                console.log("Ualter", error)
              }
            }
          }}
        />
      )}
    </TemplateProvider>
  );
}

export default Nota;