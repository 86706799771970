export const threads = (data) => {
  const result = [];
  const fixed = data.filter((d) => d.fixed);
  let currentGroupFixed = [];
  if (fixed.length > 0) {
    for (const obj of fixed) {
      if (obj.dateParagraph) {
        if (currentGroupFixed.length > 0) {
          result.push(currentGroupFixed);
        }
        currentGroupFixed = [obj];
      } else {
        currentGroupFixed.push(obj);
      }
    }
    result.push(currentGroupFixed);
  }

  const notFixed = data.filter((d) => !d.fixed);
  let currentGroup = [];
  for (const obj of notFixed) {
    if (obj.dateParagraph) {
      if (currentGroup.length > 0) {
        result.push(currentGroup);
      }
      currentGroup = [obj];
    } else {
      currentGroup.push(obj);
    }
  }

  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }
  return result;
};