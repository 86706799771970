import ContentImage from "@/shared/ContentImage";
import Slider from "@/shared/Slider";
import { getImageByClippings, getImageBySelectedClipping } from "helpers";
import { useRef, useEffect } from "react";
import { ExpandImage } from "./style";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

export const ImagesSlider = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
        props = { 
            ...props,
            images: formatContent?.imageRelatedInclude
              ? [formatContent?.dataImageRelated, ...formatContent?.dataImagesBody]
              : formatContent?.dataImagesBody,
            currentImageSlide: formatContent?.currentImageSlide,
            setCurrentImageSlide: formatContent?.setCurrentImageSlide,
        };
  } 

  const {
    images,
    currentImageSlide,
    setCurrentImageSlide = (index) => {},
  } = props;

  const refDialog = useRef();

  const show = () => {
    if (refDialog?.current) refDialog.current.showModal();
  };

  const close = () => {
    if (refDialog?.current) refDialog.current.close();
    setCurrentImageSlide(null);
  };

  useEffect(() => {
    if (currentImageSlide != null) {
      show();
    }
  }, [currentImageSlide]);

  const getSlides = () => {
    return images.map(({ clippings, epigraphe, selectedClipping }, index) => {
      let dataImage = getImageBySelectedClipping(selectedClipping);
      if (dataImage?.image !== "#") {
        dataImage = {
          desktop: dataImage,
          mobile: dataImage,
        };
      } else {
        dataImage = getImageByClippings(
          { desktop: ["Apertura_full"], mobile: ["col_listado_1x1"] },
          clippings
        );
      }
      return (
        <div key={`itemImagesSlider${index}`}>
          <picture>
            <ContentImage data={dataImage} alt={epigraphe} />
          </picture>
          <p className="text">
            {epigraphe}{" "}
            <span className="desktop-only">
              Imagen: {`${index + 1}/${images.length}`}
            </span>
          </p>

        </div>
      );
    });
  };

  return (
    <ExpandImage>
      <dialog ref={refDialog}>
        <button className="close-image" onClick={close} />
        <Slider
          isNota={true}
          items={getSlides()}
          arrowRight={<img className="arrows" src="/img/chevron-right.svg" />}
          arrowLeft={<img className="arrows" src="/img/chevron-right.svg" />}
          showIndex={currentImageSlide}
        />
      </dialog>
    </ExpandImage>
  );
};
