import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const NoscriptContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
  overflow: hidden;
  ${breakpoints.phone} {
    gap: 35px;
  }
  .noscript-logo {
    width: 140px;
    margin: 0;
  }
  .noscript-text {
    ${Font.sansRegular}
    font-size: 32px;
    line-height: 34px;
    max-width: 735px;
    text-align: center;
    ${breakpoints.phone} {
      font-size: 26px;
      line-height: 28px;
    }
  }
  .noscript-button {
    width: 238px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    ${Font.sansRegular}
    &:visited {
      text-decoration: none;
      color: #000;
    }
  }
`;

export const StoryMainContainer = styled.section.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  margin: auto;
  column-gap: 1rem;
  justify-content: center;
  width: 100%;
  /*${breakpoints.tablet} {
    max-width: 975px;
    column-gap: 0;
  }*/

  ${breakpoints.darkMode} {

    [data-theme='dark'] & {
    background: ${ColorDark.backDarknew};
    margin-top: -5px;
    padding-top: 5px;
    }
  }
  .columnContainer {
    column-gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 300px;

    ${breakpoints.phone} {
      display: none;
    }
    .container {
      position: relative;
      padding-bottom: 1rem;
    }
    .sticky-element {
      position: sticky;
      top: 50px;
      height: 600px;
      margin: 2rem 0 0 0;
      width: 300px;

      ${breakpoints.tablet} {
        top: auto;
      }

      ${breakpoints.phone} {
        display: none;
      }
    }
    .column {
      width: 300px;
      height: 100%;
      position: relative;
      ${breakpoints.tablet} {
        position: relative;
      }

      ${breakpoints.phone} {
        display: none;
      }
    }
  }
`;
