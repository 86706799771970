import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const ExpandImage = styled.div.attrs((props) => ({
  className: props.className,
}))`
  dialog {
    position: fixed;
    top: 0px;
    min-height: 100%;
    min-width: 100%;
    left: 0;
    right: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 30;
    padding: 0;
    overflow: hidden;

    .close-image {
      position: absolute;
      right: 1rem;
      top: 1rem;
      background: #fff;
      z-index: 5;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 6;
      border: none;
      &:before {
        content: "";
        background: url("/img/close-black.svg") no-repeat;
        height: 18px;
        width: 15px;
        position: absolute;
        background-size: contain;
      }
    }
  }

  .slide-container {
    background-color: #101010;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      background-color:${ColorDark.greySeparator};
      }
  }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      position: relative;
      width: 100%;
      picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        img {
          width: auto;
          height: 100%;
          ${breakpoints.phone} {
          width: 100%;
          height: auto;
        }
        }
      }
      p {
        color: #fff;
        ${Font.sansRegular};
        font-size: 20px;
        letter-spacing: -0.2px;
        line-height: 25px;
        max-width: 80%;
        padding: 20px 40px 20px 40px;
        ${breakpoints.phone} {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .text {
        position: absolute;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        max-width: 100% !important;
      }
      .desktop-only {
        display: inline;
      }
    }
  }
  .slide-group-control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 20px;
    .slide-box {
      margin: 0 1rem;
      justify-content: space-between;
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
    }
    .slide-arrow-left,
    .slide-arrow-right {
      border-radius: 50%;
      height: 40px;
      overflow: hidden;
      transition: all 0.3s;
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      z-index: 10;
      background: #fff;
      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
      &.slide-arrow-right {
        img {
          transform: rotate(180deg);
        }
      }
    }
    .slide-arrow-left {
      margin-right: 0px !important;
    }
  }
`;
